import {Fragment, useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {aboutSections} from './consts'
import {useAppContext} from './AppContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const AboutSection = ({section, isLast}) => {
  const {icon, title, id, content:Content} = section;
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <Fragment key={`section_${title}`}>
      <Box 
        display="flex" 
        sx={{
          alignItems: 'center', 
          top: '0px', 
          backgroundColor: 'background.default',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: "secondary.main"
          },
          borderRadius: '4px',
        }} 
        p={2}
        position="sticky"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Box display="flex" justifyContent="center" sx={{
          height: '34px', 
          width: '34px', 
          border: '2px solid', 
          borderRadius: '50%',
          alignItems: 'center',
        }}>{icon}</Box>
        <Typography 
          variant="h6" 
          component={Box} 
          pl={2}
          sx={{flexGrow: 1}}
        >{title}</Typography>
        <IconButton>
          <ArrowDropDownIcon sx={{
            transform: `rotate(${isCollapsed ? '' : '18'}0deg)`, 
            transition: 'transform 0.3s ease-out'
          }}/>
        </IconButton>
      </Box>
      <Collapse in={isCollapsed}><Box display="flex" pl={4} id={id}>
        <Divider orientation="vertical" sx={{height: 'auto'}}/>
        <Box pl={2} sx={{width: '100%'}}>
          <Box pt={2}><Content /></Box>
          {!isLast && <Box pt={2} pb={1}><Divider/></Box>}
        </Box>
      </Box></Collapse>
    </Fragment>
  );
}

export const About = () => {
  const context = useAppContext();
  const aboutRef = useRef();

  useEffect(() => {
  	setTimeout(() => {
	  	if(!context.nav.hash) {
	  		return;
	  	}

	  	const targetedSection = document.getElementById(context.nav.hash);
	  	if(!targetedSection) {
	  		return;
	  	}
		const scrollTop = targetedSection.offsetTop - 34;
		aboutRef.current.scrollTo({top: scrollTop, behavior: 'smooth'});
  	}, 0)
  }, [context.nav.hash]);

  return <Box 
  	ref={aboutRef}
    px={4} 
    position="relative" 
    overflow="auto" 
    sx={{height: 'calc(100% - 64px)', scrollMargin: '16px'}}
  >
    {aboutSections.map((section, i) => <AboutSection 
      section={section} 
      isLast={i === (aboutSections.length - 1)}
    />)}
  </Box>
}