import './App.css';

import {Header} from './Header';
import {Body} from './Body';
import {AppContextProvider} from './AppContext';

const App = () => {
  return (
    <AppContextProvider>
      <Header />
      <Body />
    </AppContextProvider>
  );
}

export default App;
