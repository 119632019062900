import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import {useAppContext} from './AppContext';
import {useEffect, useState} from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ReplayIcon from '@mui/icons-material/Replay';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { loadScript } from "@paypal/paypal-js";

const paypalClientId = 'AUR_Z5dsNO-nvlCf-ZVT4TDxce3x-7VibLmpi_qzoo7jN9FKlZ71ouftl55X_P3nZcmhW4gsuI6B4z63';


const LoginForm = () => {
	const context = useAppContext();

	return <Card sx={{width: 'fit-content', textAlign: 'center', margin: 'auto'}}><CardContent 
		component={Box} 
		display="flex" 
		flexDirection="column" 
		justifyContent="center"
		sx={{placeItems: 'center'}}
	>
		<Typography variant="h5" component={Box} pb={2} px={4}>Login with Twitch</Typography>
		<Button 
			variant="contained"
			onClick={() => context.auth.loginWithRedirect({loginParams: {returnTo: `${window.location.origin}/profile`}})}
		><Box component="span" px={4}>Login</Box></Button>
	</CardContent></Card>
};

const TwitchAuthorization = () => {
	const context = useAppContext();
	const [isCollapsed, setIsCollapsed] = useState(false);

	const hasGrantedRedemptionAccess = context.user?.scopes?.includes('channel:read:redemptions');
	const [redemptionsChecked, setRedemptionsChecked] = useState(hasGrantedRedemptionAccess);
	const redemptionPermissionsChanged = redemptionsChecked !== hasGrantedRedemptionAccess;
	useEffect(() => {
		setRedemptionsChecked(hasGrantedRedemptionAccess);
	}, [hasGrantedRedemptionAccess]);


	const hasGrantedHypeTrainAccess = context.user?.scopes?.includes('channel:read:hype_train');
	const [hypeTrainChecked, setHypeTrainChecked] = useState(hasGrantedHypeTrainAccess);
	const hypeTrainPermissionsChanged = hypeTrainChecked !== hasGrantedHypeTrainAccess;
	useEffect(() => {
		setHypeTrainChecked(hasGrantedHypeTrainAccess);
	}, [hasGrantedHypeTrainAccess]);

	const permissionsChagned = redemptionPermissionsChanged || hypeTrainPermissionsChanged;
	
	const scopes = [];
	if(redemptionsChecked) {
		scopes.push('channel:read:redemptions')
	}
	if(hypeTrainChecked) {
		scopes.push('channel:read:hype_train')
	}

	return <Box display="flex" flexDirection="column">
		<Box display="flex" alignItems="center">
			<Box 
				display="flex" 
				component={ListItemButton}
				onClick={() => setIsCollapsed(!isCollapsed)}
				disableGutters
			>
				<Box display="flex" sx={{flexGrow: 1}}>
					<Typography variant="h6">Authorization</Typography>
					<Box 
						pl={1}
						display="flex" 
						title={permissionsChagned ? "Unsaved changes" : "Saved!"}
					>{
						permissionsChagned
						? <>
							<WarningAmberIcon />
							<IconButton 
								display="flex" 
								title="Undo changes"
								onClick={() => {
									setRedemptionsChecked(hasGrantedRedemptionAccess);
									setHypeTrainChecked(hasGrantedHypeTrainAccess);
								}}
							>
								<ReplayIcon />
							</IconButton>
						</>
						: <CheckCircleOutlineIcon color="success"/>
					}</Box>
				</Box>
				<IconButton>
		          <ArrowDropDownIcon sx={{
		            transform: `rotate(${isCollapsed ? '' : '18'}0deg)`, 
		            transition: 'transform 0.3s ease-out'
		          }}/>
		        </IconButton>
			</Box>
		</Box>
		<Collapse in={!isCollapsed}><Box display="flex" flexDirection="column" gap={2}>
			<List pt={1} disablePadding>
			    <ListItemButton onClick={() => setRedemptionsChecked(!redemptionsChecked)} disableGutters dense>
			    	<Checkbox checked={redemptionsChecked}/> 
			    	<Typography>Redemptions</Typography>
		    	</ListItemButton>
			    <ListItemButton onClick={() => setHypeTrainChecked(!hypeTrainChecked)} disableGutters dense>
			    	<Checkbox checked={hypeTrainChecked}/> 
			    	<Typography>Hype Train</Typography>
		    	</ListItemButton>
			</List>
			<Button 
				variant="contained" 
				disabled={!permissionsChagned}
				title={permissionsChagned ? undefined : 'Selected permissions saved!'}
				component="a"
				href={`
					https://id.twitch.tv/oauth2/authorize
					?response_type=token
					&client_id=q7w3ov5ih2zbb46pb7mzudsfnekvvx
					&redirect_uri=${window.location.origin}/profile/
					&scope=${scopes.map(encodeURIComponent).join('+')}
				`}
			>{permissionsChagned ? 'Authorize' : 'Authorized'}</Button>
		</Box></Collapse>
	</Box>
};

const ProfileSecret = () => {
	const context = useAppContext();

	return <TextField 
		InputProps={{
			endAdornment: (
				<IconButton 
					display="flex" 
					title="Regenerate secret"
					onClick={context.regenerateSecret}
				>
					<ReplayIcon />
				</IconButton>
			)
		}}
		label="Secret"
		value={context.user?.secret || ''} 
		disabled
	/>
};

const PurchaseCreditsForm = () => {
	const [loaded, setLoaded] = useState(false);
	useEffect(() => {
		if(loaded) {
			return;
		}
		loadScript({ clientId: paypalClientId })
		.then(paypal => {
			paypal.Buttons({
				style: {
					color: 'silver', 
					layout: 'horizontal', 
					disableMaxWidth: true,
					label: 'checkout',
				}
			}).render('#fated-checkout-button')
			setLoaded(true);
		})
	}, [loaded]);
	
	return <Box display="flex" flexDirection="column" gap={2}>
		<Typography variant="h6">Purchase credits</Typography>
		<TextField 
			label="Amount (USD)" 
			type="number" 
			inputProps={{min: 0,  max: 1000}}
			sx={{minWidth: '100px'}}
			onKeyPress={(e) => {
				if(['-', 'e', '^'].includes(String.fromCharCode(e.charCode))) {
					e.preventDefault();
					return;
				}
				setTimeout(() => {
					if(e.target.valueAsNumber > 1000) {
						e.target.value = '1000'
					}
				}, 0);
			}}
		/>
		<div 
			id="fated-checkout-button"
			variant="contained"
		/>
	</Box>
};

const downloadText = (filename, text) => {
	const element = document.createElement('a');
	element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
	element.setAttribute('download', filename);

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}

const WidgetProfileSection = () => {
	const {widget, user, nav} = useAppContext();
	const isAccesTokenInHash = nav.hash.startsWith('access_token=');
	const widgetCode = widget.code;
	const settings = widget.settings;

	const [recentlyWasCopied, setRecentlyWasCopied] = useState(false);
	const [isCollapsed, setIsCollapsed] = useState(false);

	const [isTtsChecked, setisTtsChecked] = useState(settings.tts.enabled);
	const [isDeleteCountersChecked, setIsDeleteCountersChecked] = useState(settings.deleteCounters.enabled);
	const [isPlayerLotteryChecked, setIsPlayerLotteryChecked] = useState(settings.playerLottery.enabled);

	const [playOnRedeem, setPlayOnRedeem] = useState(settings.tts.playOnRedeem);
	const [playOnHypeEvent, setPlayOnHypeEvent] = useState(settings.tts.playOnHypeEvent);
	const [audioUrl, setAudioUrl] = useState(settings.tts.audioClipUrl);
	const [playAudioOnRedeem, setPlayAudioOnRedeem] = useState(settings.tts.playAudioOnRedeem);
	const [playAudioOnHypeEvent, setPlayAudioOnHypeEvent] = useState(settings.tts.playAudioOnHypeEvent);

	const hasUnsavedChanges = (
		isTtsChecked !== settings.tts.enabled ||
		isDeleteCountersChecked !== settings.deleteCounters.enabled ||
		isPlayerLotteryChecked !== settings.playerLottery.enabled || 
		playOnRedeem !== settings.tts.playOnRedeem || 
		playOnHypeEvent !== settings.tts.playOnHypeEvent ||
		audioUrl !== settings.tts.audioClipUrl ||
		playAudioOnRedeem !== settings.tts.playAudioOnRedeem ||
		playAudioOnHypeEvent !== settings.tts.playAudioOnHypeEvent
	);
	const [isLoading, setIsLoading] = useState(false);

	return <Paper py={2}><Box p={2} display="flex" flexDirection="column" gap={2}>
		<Box display="flex" gap={2}>
			<Typography sx={{flexGrow: 1}} variant="h4">Widget</Typography>
			<Button 
				startIcon={<ContentCopyIcon/>} 
				variant="contained"
				onClick={()=>{
					if(recentlyWasCopied) {
						return;
					}
					navigator.clipboard.writeText(widgetCode);
					setRecentlyWasCopied(true);
					setTimeout(() => {
						setRecentlyWasCopied(false);
					}, 5000)
				}}
			>{recentlyWasCopied ? 'Copied!' : 'Copy'}</Button>
			<Button 
				startIcon={<DownloadIcon />}
				onClick={() => downloadText('FatedWidget', widgetCode)}
				variant="contained"
			>Download</Button>
		</Box>
		<Divider />
		{
			user?.secret ?
			<ProfileSecret /> : 
			<CircularProgress />
		}
		<Divider />
		{
			!isAccesTokenInHash && user?.scopes ?
			<TwitchAuthorization /> :
			<CircularProgress />
		}
		<Divider />
		<Box 
			component={ListItemButton}
			display="flex" 
			onClick={() => setIsCollapsed(!isCollapsed)}
	        title={hasUnsavedChanges ? 'Unsaved changes' : undefined}
			disableGutters
        >
			<Box display="flex" sx={{flexGrow: 1}} alignItems="center"><Typography 
				sx={{userSelect: 'none'}} 
				variant="h6" 
				px={1}
			>Features</Typography>
			{
				hasUnsavedChanges
				? <WarningAmberIcon />
				: <CheckCircleOutlineIcon color="success"/>
			}
			</Box>
			<IconButton>
	          <ArrowDropDownIcon sx={{
	            transform: `rotate(${isCollapsed ? '' : '18'}0deg)`, 
	            transition: 'transform 0.3s ease-out'
	          }}/>
	        </IconButton>
		</Box>
		<Collapse in={!isCollapsed}><Box display="flex" flexDirection="column" gap={2}>
				<List disablePadding>
					<ListItemButton onClick={()=>{
						const newCheckedVal = !isTtsChecked;
						setisTtsChecked(newCheckedVal)
						if(!newCheckedVal) {
							setPlayOnRedeem(false);
							setPlayOnHypeEvent(false);
						} else {
							setPlayOnRedeem(settings.tts.playOnRedeem);
							setPlayOnHypeEvent(settings.tts.playOnHypeEvent);
						}
					}} disableGutters dense>
				    	<Checkbox checked={isTtsChecked}/> 
				    	<Typography>TTS</Typography>
			    	</ListItemButton>
			    	<Collapse in={isTtsChecked}><Box display="flex" pl={2}>
		    			<Divider orientation="vertical" flexItem sx={{flexShrink: 0}}/>
			    		<Box display="flex" flexDirection="column">
				    		<ListItem>
			    				<TextField 
			    					label="Leading clip URL"
					    			variant="outlined"
					    			defaultValue={audioUrl} 
					    			onChange={event => setAudioUrl(event.target.value)}
					    			sx={{width: 'inherit'}}
			    				/>
			    			</ListItem>
				    		<ListItemButton onClick={()=>setPlayOnRedeem(!playOnRedeem)} disableGutters dense>
						    	<Checkbox checked={playOnRedeem}/> 
						    	<Typography>Play on channel point redemptions</Typography>
					    	</ListItemButton>
					    	<Collapse in={playOnRedeem}><Box display="flex" pl={2}>
						    	<Divider orientation="vertical" flexItem sx={{flexShrink: 0}}/>
					    		<ListItemButton onClick={()=>setPlayAudioOnRedeem(!playAudioOnRedeem)} disableGutters dense>
							    	<Checkbox checked={playAudioOnRedeem}/> 
							    	<Typography>Play leading audio clip</Typography>
						    	</ListItemButton>
					    	</Box></Collapse>
					    	<ListItemButton onClick={()=>setPlayOnHypeEvent(!playOnHypeEvent)} disableGutters dense>
						    	<Checkbox checked={playOnHypeEvent}/> 
						    	<Typography>Play on cheers, tips, and subs</Typography>
					    	</ListItemButton>
					    	<Collapse in={playOnHypeEvent}><Box display="flex" pl={2}>
						    	<Divider orientation="vertical" flexItem sx={{flexShrink: 0}}/>
					    		<ListItemButton onClick={()=>setPlayAudioOnHypeEvent(!playAudioOnHypeEvent)} disableGutters dense>
							    	<Checkbox checked={playAudioOnHypeEvent}/> 
							    	<Typography>Play leading audio clip</Typography>
						    	</ListItemButton>
					    	</Box></Collapse>
				    	</Box>
			    	</Box></Collapse>
			    	<ListItemButton onClick={()=>setIsDeleteCountersChecked(!isDeleteCountersChecked)} disableGutters dense>
				    	<Checkbox checked={isDeleteCountersChecked}/> 
				    	<Typography>Delete Counters</Typography>
			    	</ListItemButton>
			    	<ListItemButton onClick={()=>setIsPlayerLotteryChecked(!isPlayerLotteryChecked)} disableGutters dense>
				    	<Checkbox checked={isPlayerLotteryChecked}/> 
				    	<Typography>Player Lottery</Typography>
			    	</ListItemButton>
				</List>
				<Button
					disabled={!hasUnsavedChanges || isLoading}
					variant="contained"
					onClick={async () => {
						if(typeof widget?.saveSettings !== 'function') {
							return;
						}
						setIsLoading(true);
						await widget.saveSettings({
							tts: {
								enabled: isTtsChecked,
								playOnRedeem,
								playOnHypeEvent,
								audioUrl,
								playAudioOnRedeem,
								playAudioOnHypeEvent,
							},
							deleteCounters: {
								enabled: isDeleteCountersChecked,
							},
							playerLottery: {
								enabled: isPlayerLotteryChecked,
							}
						})
						setIsLoading(false);
					}}
				>{hasUnsavedChanges ? 'Save' : 'Saved'}</Button>
			</Box></Collapse>
	</Box></Paper>
};

const AuthenticatedProfile = () => {
	const context = useAppContext();

	return <Box display="flex" flexDirection="column" gap={2}>
		<Box display="flex">
			<Typography sx={{flexGrow: 1}} variant="h6">{context?.user?.display_name}</Typography>
			<Button variant="contained" onClick={() => {
				context.auth.logout({
					logoutParams: {
						returnTo: `${window.location.origin}/profile`
					}
				})
			}}>Logout</Button>
		</Box>
		<Divider />
		<Paper><Box p={2} display="flex">
			<Box sx={{flexGrow: 1}} pr={2}>
				<Typography variant="h6">Twitch account created: {
					new Date(context.user?.created_at).toLocaleDateString(
						undefined, 
						{
							dateStyle: 'medium'
						}
					)
				}</Typography>
				<Box py={2}><Divider /></Box>
				<Typography variant="h6">Credits available: {
					Intl.NumberFormat('en-US', {
						style: 'currency', 
						currency: 'USD'
					}).format(context.credits.available)
				}</Typography>
				<Typography variant="h6">Total credits purchased: {
					Intl.NumberFormat('en-US', {
						style: 'currency', 
						currency: 'USD'
					}).format(context.credits.purchsed)
				}</Typography>
			</Box>
			<Divider orientation="vertical" flexItem sx={{flexShrink: 0}}/>
			<Box display="flex" pl={2}>
				<PurchaseCreditsForm />
			</Box>
		</Box></Paper>
		<Divider />
		{
			context.widget.settings.loaded ?
			<WidgetProfileSection /> :
			<CircularProgress />
		}
	</Box>
};

export const Profile = () => {
	const context = useAppContext();

	return <Box 
		p={4}
		position="relative" 
		overflow="auto" 
		sx={{height: 'calc(100% - 64px)'}}
	>
		{
			context.isInitializing ? <CircularProgress /> : 
			context.auth.isAuthenticated ? <AuthenticatedProfile /> :
			<LoginForm />
		}
	</Box>
};