import {useEffect} from 'react';
import {aboutSections} from './consts';
import {FatedListLink} from './components'
import {useState, Fragment} from 'react';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import {useAppContext} from './AppContext';

export const Header = () => {
  const context = useAppContext();

  const [breadcrumbItems] = useState([
    {
      title: 'Fated TTS',
      url: '/#',
      variant: 'h6',
    },
  ]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expandedDrawerItems, setExpandedDrawerItems] = useState([]);

  const handleExpandIconClick = (id, isOpen) => setExpandedDrawerItems(
    items => (
      isOpen ?
      items.filter(itemId => itemId !== id) : 
      Array.from(new Set([
        ...items,
        id,
      ]))
    )
  );

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  useEffect(() => {
    window.addEventListener('hashchange', closeDrawer);
    return () => {
      window.removeEventListener('hashchange', closeDrawer);
    };
  }, []);

  return <>
    <AppBar position="static"  sx={{backgroundColor: 'primary.dark'}}>
      <Toolbar>
        <IconButton 
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={openDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Breadcrumbs separator="›" sx={{ flexGrow: 1 }}>
          {breadcrumbItems.map(({title, url, variant='body1'}) => <Link 
            component={Button}
            key={`crumb_${url}`}
            href={url} 
            variant={variant} 
            color="text.primary"
            underline="hover"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: "primary.active"
              }
            }}
          >{title}</Link>)}
        </Breadcrumbs>
        {
          typeof context.credits?.available === 'number' ? 
          <Link 
            component={Button}
            href="/profile/#"
            color="text.primary"
            underline="hover"
            fontWeight="bold"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: "primary.active"
              }
            }}
          >{
            Intl.NumberFormat('en-US', {
              style: 'currency', 
              currency: 'USD'
            }).format(context.credits.available)
          }</Link> :
          <CircularProgress size={24}/>
        }
        <IconButton 
        	size="large" 
        	component="a" 
        	href="/profile/#"
    	>
          {
            context.auth.isAuthenticated && context.user?.profile_image_url ?
            <Avatar src={context.user.profile_image_url} sx={{height: 24, width: 24}}/> :
            <AccountCircle />
          }
        </IconButton>
      </Toolbar>
      <Divider />
    </AppBar>
    <Drawer 
      anchor="left"
      open={drawerOpen}
      onClose={closeDrawer}
    >
      <List 
      	component={Box} 
      	sx={{width: 250, height: '100%'}} 
        display="flex" 
        flexDirection="column" 
        justifyContent="space-between"
        dense={true}
        disablePadding={true}
      >
        <Box>
          {drawerTopNavItems.map(navItem => {
            const isOpen = expandedDrawerItems.includes(navItem.url);

            return <Box key={`nav_item_${navItem.url}`}>
              <FatedListLink 
                {...navItem}
                isOpen={isOpen}
                onIconClick={() => handleExpandIconClick(navItem.url, isOpen)}
              />
              {
                navItem.subItems?.length ?
                <Collapse in={isOpen} sx={{pl: 2}}>{navItem.subItems.map(FatedListLink)}</Collapse> :
                null
              }
            </Box>
          })}
        </Box>
        <Box key={`profile_nav_item_${profileNavItem.url}`} pb={1}>
          <FatedListLink 
            {...profileNavItem}
          />
        </Box>
      </List>
    </Drawer>
  </>;
}

const drawerTopNavItems = [
  {
    title: 'About',
    url: '/#',
    subItems: aboutSections.map(({title, id}) => ({
      title,
      url: `/#${id}`,
    }))
  },
];

const profileNavItem = {
  title: 'Profile',
  url: '/profile/#',
};
