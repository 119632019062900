import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import StartIcon from '@mui/icons-material/Start';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import LightModeIcon from '@mui/icons-material/LightMode';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RouteIcon from '@mui/icons-material/Route';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';


export const aboutSections = [
  {
    icon: <QuestionMarkIcon/>,
    title: 'What is Fated TTS',
    id: 'about',
    content: () => <Paper><Box p={4}>
      <Typography>
        Fated TTS is a custom StreamElements widget that adds a text-to-speech feature to your 
        livestreams. It detects when a channel point redemption has happened with "TTS" in the 
        title and generates audible speech from the message. the AI service corresponding to the 
        selected voice will convert the text into natural-sounding speech. It then gets played 
        by the widget, which should be audible to you and the audience. The default voice (Brian) 
        is free. To use any other available voice, you must have credits on your account. 
      </Typography>
    </Box></Paper>
  },
  {
    icon: <StartIcon />,
    title: 'How it began',
    id: 'beginning',
    content: () => <Paper><Box p={4}>
      <Typography>
        I found a Twitch community that I felt a strong connection with and found myself looking
        for ways to help facilitate a positive experience. After building a few visual widgets and
        doing more research, I made several interesting discoveries: audio can be played from them, 
        external servers can be communicated with, and redepmption messages in chat can be matched 
        with their corresponding redemption event to detect a TTS message. The first iteration of 
        the TTS used the&nbsp;
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/API/Web_Speech_API/Using_the_Web_Speech_API"
          target="_blank"
          rel="noreferrer"
        >Web Speech API</a>
        , which generates robotic-sounding audio. From there, I discovered StreamElements has their own
        TTS service, the Brian voice, which is free to use from the widget. Brian is great,
        but he's not very natural-sounding. After exploring the speech generation services offered
        I added support for several external AI voice services. These have a small cost to 
        generate audio, which will automatically be deducted from your available credits. After
        a dozen or more streamers asked for access to the TTS feature, I developed this site
        in order to empower any streamer to use the tool! 
      </Typography>
    </Box></Paper>
  },
  {
    icon: <PersonSearchIcon />,
    title: 'Who am I?',
    id: 'developer',
    content: () => <Paper><Box p={4}>
      <Typography>
        They call me Fated! Professionally, I'm a software engineer, focusing on web development. 
        I live in California, where I was born and raised. I went to UC Berkeley and received a 
        BA in Cultural Anthropology. After graduation, I taught myself how to code and attended
        a coding bootcamp. After which, I landed a job with a company that develops a password
        manager. I stuck with that job for four years before moving onto an ag company,
        which I'm working for now. Perosnally, I love to dogs, I like to garden, and I enjoy 
        building useful software for people. Reach out to me if you have any questions! (Please 
        no job offers or project proposals, I have too much on my plate as is. Thanks!)
      </Typography>
    </Box></Paper>
  },
  {
    icon: <MergeTypeIcon/>,
    title: 'Integration made easy',
    id: 'integration',
    content: () => <Paper><Box p={4}>
      <Typography>
        Integration is easy! All the code for the widgets are compiled into a single line
        of optimized JavaScript code. It just needs to be copied into the JS tab of a custom
        widget. To make a custom widget, navigate to the overlay sections of your StreamElements
        dashboard and click the "New Overlay" button. Open the code editor under "Settings" on
        the left. Clear out all the code in the HTML, CSS, and JS tabs. Paste the widget code
        into the JS tab and click "Done". It can now be tested by redeeming a TTS or sending a 
        relevant command on your channel. Don't forget to press press "Save" at the top right! 
      </Typography>
    </Box></Paper>
  },
  {
    icon: <LightModeIcon />,
    title: 'Transparency',
    id: 'transparency',
    content: () => <Paper><Box p={4}>
      <Typography>
        This widget is not endorsed by StreamElements. There are many custom widgets created by
        StreamElements code gurus, who are able to get their widgets endorsed by StreamElements.
        This enables them to share the widget with an easy link that copies it to your profile. 
        There are rules the gurus must follow to have their widget accepted, rules that my widget
        directly violates. The primary rules I violate is communication with external servers. 
        It's understandable for them to not want to accept widgets that do that, since they have
        no direct control over those servers and therefore is not willing to take on the risk 
        that those servers could cause the widget to do something innapropriate. In this case, 
        external servers have to be communicated with since the AI voices are hosted by different 
        companies on different servers. The second rule I violate is "no minified code". Minified
        code is compressed into as small a file as possible, becoming unreadable to humans. Smaller
        files are faster to read and process to it optimizes performance. StreamElements widgets 
        are programmed in JavaScript, and I use a standard code compiler called webpack to compile
        all the separate files into a single one and minifies it for optimal performance. If this 
        was the only rule my code had violated to be officially accepted, I'd seek a way to not 
        compile without minifying such that it's human-readable. However, since there's no benefit
        to it anymore and it makes performance worse, I'm not going to make that change. All the
        pre-compiled code is publicly available for you to view, download, modify, and use however
        you want. Please feel free to look through it to get an idea of how the system works, I'd
        love to hear your thoughts on it!
      </Typography>
    </Box></Paper>
  },
  {
    icon: <RecordVoiceOverIcon />,
    title: 'Voices',
    id: 'voices',
    content: () => <Paper><Box p={4}>
      <Typography>
        
      </Typography>
    </Box></Paper>
  },
  {
    icon: <AttachMoneyIcon />,
    title: 'Pricing',
    id: 'pricing',
    content: () => <Paper><Box p={4}>
      <Typography>
        
      </Typography>
    </Box></Paper>
  },
  {
    icon: <RouteIcon />,
    title: 'Roadmap',
    id: 'roadmap',
    content: () => <Paper><Box p={4}>
      <Typography>
        
      </Typography>
    </Box></Paper>
  },
];
