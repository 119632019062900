import {useRef} from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const FatedListLink = (props={}) => {
  const urlPath = window.location.pathname + (window.location.hash || '#');
  const isSelected = urlPath === props.url;
  const linkRef = useRef();
  const hasClickableIcon = typeof props?.onIconClick === 'function';

  return <ListItem
    key={`crumb_${props.url}`}
    disablePadding={true}
    sx={{px:1, pt: 1}}
  >
    <ListItemButton 
      onClick={() => (
        isSelected && hasClickableIcon ? 
        props.onIconClick() : 
        linkRef.current.click()
      )} 
      selected={isSelected}
      sx={{
        justifyContent: "space-between", 
        cursor: props.url ? 'pointer' : 'default', 
      }}
    >
    <Link
      href={isSelected ? undefined : props.url} 
      ref={linkRef}
      color="inherit" 
      underline={props.url && !isSelected ? "hover" : "none"}
      sx={{flexGrow: 1}}
      display="flex"

    >
      <Box component="span" sx={{flexGrow: 1, placeSelf: 'center'}}>{props.title}</Box>
      {
        hasClickableIcon ? 
        <IconButton sx={{height: '32px', width: '32px'}} onClick={(e) => {
            e.preventDefault()
            e.stopPropagation();
            props.onIconClick(e)
          }}><ArrowDropDownIcon sx={{
            transform: `rotate(${props.isOpen ? '18' : ''}0deg)`, 
            transition: 'transform 0.3s ease-out'
          }}/></IconButton> :
        null
      }
    </Link>
    </ListItemButton>
  </ListItem>
}