import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { Auth0Provider } from '@auth0/auth0-react';

const onRedirectCallback = (appState) => {
  window.history.pushState({}, '',
    appState && appState.returnTo ? appState.returnTo : `${window.location.origin}/profile`
  );
};

const providerConfig = {
  domain: "dev-wfc3ru5w.us.auth0.com",
  clientId: "cXZDHcaoVjOsykIzAxNPS40Hj8Ws75tK",
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/profile`,
    audience: "https://dev-wfc3ru5w.us.auth0.com/api/v2/",
  },
  // useRefreshTokens: true,
  cacheLocation: "localstorage"
};

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#053f43',
      active: 'rgba(255, 255, 255, 0.1)',
    },
    secondary: {
      main: '#3b1849',
    },
    error: {
      main: '#4D194D',
    },
    success: {
      main: '#08a06e',
    },
    warning: {
      main: '#3E1F47',
    },
    text: {
      primary: '#cccccc',
    },
    info: {
      main: '#0B525B',
    },
    background:{
      default: '#2d2d2d',
      paper: '#424242',
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider {...providerConfig}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>
);
