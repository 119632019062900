import {useAppContext} from './AppContext';
import {About} from './About';
import {Profile} from './Profile';
import CircularProgress from '@mui/material/CircularProgress';

const contentMap = {
  profile: Profile,
  about: About,
  loading: CircularProgress
}

export const Body = () => {
  const context = useAppContext();
  const contentKey = context.nav.chain[0];
  const Content = contentMap[contentKey];

  return <Content />
}