import {APPLICATION_ID} from './keys.js';

const getTwitchRequestHeaders = (token) => {
  return new Headers({
    ...(token ? {"Authorization": `Bearer ${token}`} : {}),
    "Client-Id": APPLICATION_ID,
  })
};

export const twitchFetch = async (url, token) => {
  const res = await fetch(url, {headers: getTwitchRequestHeaders(token)});
  return res?.json() || {};
};

export const getUser = async (userId, token) => {
  const res = await twitchFetch(`https://api.twitch.tv/helix/users?id=${userId}`, token);
  const user = res?.data?.length && res.data[0];
  return user || {};
}

const getAutheticatedHeader = async (getAccessTokenSilently) => {
  const token = await getAccessTokenSilently({
    authorizationParams: {
      audience: 'https://dev-wfc3ru5w.us.auth0.com/api/v2/',
      scope: 'read:client_grants',
    },
  });

  return new Headers({
    Authorization: `Bearer ${token}`,
  })
}

const authenticatedFetch = async (getAccessTokenSilently, url) => {
  const headers = await getAutheticatedHeader(getAccessTokenSilently);
  return fetch(url, {headers});
}

export const getUserToken = async (getAccessTokenSilently, id) => {
  const res = await authenticatedFetch(getAccessTokenSilently, `/protected/tokens/${id}`);
  const text = typeof res.text === 'function' ? await res.text() : null;
  return text;
}

export const setUserToken = async (getAccessTokenSilently, id, token) => {
  const res = await authenticatedFetch(getAccessTokenSilently, `/protected/tokens/${id}/${token}`);
  return res.status === 200;
}

export const getUserSecret = async (getAccessTokenSilently ,id) => {
  const res = await authenticatedFetch(getAccessTokenSilently, `/protected/secrets/${id}`);
  let text = typeof res.text === 'function' ? await res.text() : null;
  if(!text) {
    text = await regenerateUserSecret(getAccessTokenSilently ,id);
  }
  return text;
}

export const regenerateUserSecret = async (getAccessTokenSilently, id) => {
  const res = await authenticatedFetch(getAccessTokenSilently, `/protected/secrets/${id}/true`);
  const text = typeof res.text === 'function' ? await res.text() : null;
  return text;
}

export const getWidgetCode = async (getAccessTokenSilently, targetWidget) => {
  const res = await authenticatedFetch(getAccessTokenSilently, `/protected/widgets/${targetWidget}`);
  const text = typeof res.text === 'function' ? await res.text() : null;
  return text;
}

export const getTwitchAccessTokenScope = async (getAccessTokenSilently, id, token) => {
  const res = await authenticatedFetch(getAccessTokenSilently, `/protected/tokens/${id}/${token}/true`)
  const json = await res.json();
  return json;
};

export const getCredits = async (getAccessTokenSilently, id) => {
  const res = await authenticatedFetch(getAccessTokenSilently, `/protected/credits/${id}`);
  const text = await res?.text();
  const [available, total] = text?.split(',').map(Number);
  return [
    !isNaN(available) ? available : 0, 
    !isNaN(total) ? total : 0,
  ];
}

export const getWidgetSettings = async (getAccessTokenSilently, id, targetWidget) => {
  const res = await authenticatedFetch(getAccessTokenSilently, `/protected/widgets/${targetWidget}/${id}`);
  const text = await res?.text();
  return text;
};

export const setWidgetSettings = async (getAccessTokenSilently, id, targetWidget, settings) => {
  const res = await authenticatedFetch(getAccessTokenSilently, `/protected/widgets/${targetWidget}/${id}/${settings}`);
  return res.status === 200;
};

export const getPaypalToken = (getAccessTokenSilently) =>
  authenticatedFetch(getAccessTokenSilently, 'protected/paypal/token');
