import {
  createContext, 
  useEffect, 
  useContext, 
  useState,
  useCallback,
} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {
  getUserToken, 
  getUser, 
  getWidgetCode, 
  getTwitchAccessTokenScope, 
  setUserToken,
  getCredits,
  getUserSecret,
  regenerateUserSecret,
  getWidgetSettings,
  setWidgetSettings,
  getPaypalToken,
} from './fetch';
const FATED_USER_ID = '184426448';
const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const authContext = useAuth0();
  console.log('authContext: ', authContext)
  const {getAccessTokenSilently} = authContext;
  const [isInitializing, setIsInitializing] = useState(true);
  const [navChain, setNavChain] = useState(['loading']);
  const [navHash, setNavHash] = useState(window.location.hash.slice(1));
  const [ownerToken, setOwnerToken] = useState(null);
  const [user, setUser] = useState(null);
  const [fatedWidgetCode, setFatedWidgetCode] = useState('');
  
  const [availableCredits, setAvailableCredits] = useState(null);
  const [totalCreditsPurchased, setTotalCreditsPurchased] = useState(null);

  const [ttsEnabled, setTtsEnabled] = useState(null);
  const [deleteCountersEnabled, setDeleteCountersEnabled] = useState(null);
  const [playerLotteryEnabled, setPlayerLotteryEnabled] = useState(null);
  const [ttsPlayOnRedeem, setTtsPlayOnRedeem] = useState(null);
  const [ttsPlayAudioOnRedeem, setTtsPlayAudioOnRedeem] = useState(null);
  const [ttsPlayOnHypeEvent, setTtsPlayOnHypeEvent] = useState(null);
  const [ttsPlayAudioOnHypeEvent, setTtsPlayAudioOnHypeEvent] = useState(null);
  const [ttsLeadingClipUrl, setTtsLeadingClipUrl] = useState(null);
  const [paypalToken, setPaypalToken] = useState(null);


  const hashChangeHandler = useCallback(() => {
    setNavHash(window.location.hash.slice(1));
  }, []);

  useEffect(() => {
    if(authContext.isLoading || !authContext?.user) {
      setIsInitializing(false);
      return;
    }

    // lookup the user with their twitch userId
    const {sub:userIdString} = authContext?.user || {};

    const userIdSubstrings = userIdString?.split('|');
    const twitchId = userIdSubstrings[2];

    const cachedUser = localStorage.getItem(userIdString)
    if(cachedUser) {
      setUser(JSON.parse(cachedUser));
    }

    Promise.all([
      getUserToken(getAccessTokenSilently, FATED_USER_ID), 
      getWidgetCode(getAccessTokenSilently, 'CORE_WIDGET'),
      getCredits(getAccessTokenSilently, twitchId),
      getUserSecret(getAccessTokenSilently, twitchId),
      getWidgetSettings(getAccessTokenSilently, twitchId, 'TTS'),
      getWidgetSettings(getAccessTokenSilently, twitchId, 'DELETE_COUNTERS'),
      getWidgetSettings(getAccessTokenSilently, twitchId, 'PLAYER_LOTTERY'),
      getPaypalToken(getAccessTokenSilently),
    ])
    .then(async ([
      _ownerToken, 
      coreWidgetCode,
      fetchedCreditsArray,
      secret,
      ttsSettings,
      deleteCounterSettings,
      playerLotterySettings,
      fetchedPaypalToken,
    ]) => {
      setOwnerToken(_ownerToken);
      setFatedWidgetCode(coreWidgetCode);

      const [fetchedAvailableCredits, fetchedTotalPurchasedCredits] = fetchedCreditsArray;
      setAvailableCredits(fetchedAvailableCredits);
      setTotalCreditsPurchased(fetchedTotalPurchasedCredits);

      // now we have the user ID, fetch them from the twitch endpoint
      const isAccessTokenInHash = navHash.startsWith('access_token=');
      
      let token, scopes = [];
      if(isAccessTokenInHash) {
        const frags = navHash.split('&');
        const hashParams = {};
        frags.forEach(frag => {
          const [fragKey, fragVal] = frag.split('=');
          hashParams[fragKey] = fragVal;
        })
        const {access_token} = hashParams;
        await setUserToken(getAccessTokenSilently, twitchId, access_token)
        token = access_token;
        scopes = hashParams.scope.split('+').map(decodeURIComponent);
      } else {
        token = await getUserToken(getAccessTokenSilently, twitchId);
        try {
          const getScopesRes = await getTwitchAccessTokenScope(getAccessTokenSilently, twitchId, token);
          if(getScopesRes?.scopes) {
            scopes = getScopesRes.scopes;
          }
        } catch(err) {}
      }

      if(isAccessTokenInHash) {
        setNavHash('#');
        window.history.pushState({}, null, '#');
      }

      const user = await getUser(twitchId, _ownerToken);

      const userObj = {
        ...user,
        token,
        scopes,
        secret,
      }

      setUser(userObj);
      localStorage.setItem(userIdString, JSON.stringify(userObj));

      const [
        fetchedTtsEnabled,
        fetchedPlayOnRedeem,
        fetchedPlayOnHypeEvent,
        fetchedAudioUrl,
        fetchedPlayAudioOnRedeem,
        fetchedPlayAudioOnHypeEvent,
      ] = ttsSettings.split('-');

      setTtsEnabled(fetchedTtsEnabled === '1');
      setTtsPlayOnRedeem(fetchedPlayOnRedeem === '1');
      setTtsPlayOnHypeEvent(fetchedPlayOnHypeEvent === '1');
      setTtsPlayAudioOnRedeem(fetchedPlayAudioOnRedeem === '1');
      setTtsPlayAudioOnHypeEvent(fetchedPlayAudioOnHypeEvent === '1');
      setTtsLeadingClipUrl(fetchedAudioUrl);
      setDeleteCountersEnabled(deleteCounterSettings === '1');
      setPlayerLotteryEnabled(playerLotterySettings === '1');
      console.log('fetchedPaypalToken res: ', fetchedPaypalToken)
      setPaypalToken(fetchedPaypalToken);

      setIsInitializing(false);
    });

    window.addEventListener('hashchange', hashChangeHandler);
    return () => {
      window.removeEventListener('hashchange', hashChangeHandler);
    };
  // eslint-disable-next-line
  }, [authContext.isLoading]);

  useEffect(() => {
    const {pathname} = window.location;
    let navNodes = pathname.split('/');
    // remove the hash from the final element in the url path, if there is one
    navNodes[navNodes.length-1] = navNodes[navNodes.length-1].split('#')[0];
    // remove all the empty strings by removing all falsy values with the
    navNodes = navNodes.filter(Boolean);

    setNavChain(navNodes.length ? navNodes : ['about']);
  // eslint-disable-next-line
  }, [window.location.href]);

  const context = {
    isInitializing,
    nav: {
      chain: navChain,
      hash: navHash,
    },
    auth: authContext,
    ownerToken,
    user,
    credits: {
      purchsed: totalCreditsPurchased,
      available: availableCredits,
    },
    regenerateSecret: async () => {
      const newSecret = await regenerateUserSecret(getAccessTokenSilently, user.id);
      const newUserObj = {
        ...user,
        secret: newSecret,
      };
      setUser(newUserObj);
      localStorage.setItem(user.id, JSON.stringify(newUserObj));
    },
    widget: {
      code: fatedWidgetCode,
      settings: {
        loaded: (
          ttsEnabled !== null &&
          deleteCountersEnabled !== null &&
          playerLotteryEnabled !== null &&
          ttsPlayOnRedeem !== null &&
          ttsPlayOnHypeEvent !== null
        ),
        tts: {
          enabled: ttsEnabled,
          playOnRedeem: ttsPlayOnRedeem,
          playOnHypeEvent: ttsPlayOnHypeEvent,
          audioClipUrl: ttsLeadingClipUrl,
          playAudioOnHypeEvent: ttsPlayAudioOnHypeEvent,
          playAudioOnRedeem: ttsPlayAudioOnRedeem,
        },
        deleteCounters: {
          enabled: deleteCountersEnabled,
        },
        playerLottery: {
          enabled: playerLotteryEnabled
        },
      },
      saveSettings: async (newSettings) => {
        if(
          newSettings.tts.enabled !== context.widget.settings.tts.enabled ||
          newSettings.tts.playOnRedeem !== context.widget.settings.tts.playOnRedeem || 
          newSettings.tts.playOnHypeEvent !== context.widget.settings.tts.playOnHypeEvent ||
          newSettings.tts.audioClipUrl !== context.widget.settings.tts.audioClipUrl ||
          newSettings.tts.playAudioOnHypeEvent !== context.widget.settings.tts.playAudioOnHypeEvent ||
          newSettings.tts.playAudioOnRedeem !== context.widget.settings.tts.playAudioOnRedeem
        ) {
          setTtsEnabled(newSettings.tts.enabled);
          setTtsPlayOnRedeem(newSettings.tts.playOnRedeem);
          setTtsPlayOnHypeEvent(newSettings.tts.playOnHypeEvent);

          await setWidgetSettings(getAccessTokenSilently, user.id, 'TTS', `${
            newSettings.tts.enabled ? '1' : '0'
          }-${
            newSettings.tts.playOnRedeem ? '1' : '0'
          }-${
            newSettings.tts.playOnHypeEvent ? '1' : '0'
          }-${
            newSettings.tts.playAudioOnRedeem ? '1' : '0'
          }-${
            newSettings.tts.playAudioOnHypeEvent ? '1' : '0'
          }-${
            newSettings.tts.audioUrl || ''
          }`)
        }

        if(newSettings.deleteCounters.enabled !== context.widget.settings.deleteCounters.enabled) {
          setDeleteCountersEnabled(newSettings.deleteCounters.enabled);

          await setWidgetSettings(
            getAccessTokenSilently,
            user.id, 
            'DELETE_COUNTERS', 
            `${newSettings.deleteCounters.enabled ? '1' : '0'}`
          )
        }

        if(newSettings.playerLottery.enabled !== context.widget.settings.playerLottery.enabled) {
          setPlayerLotteryEnabled(newSettings.playerLottery.enabled);

          await setWidgetSettings(
            getAccessTokenSilently,
            user.id, 
            'PLAYER_LOTTERY', 
            `${newSettings.playerLottery.enabled ? '1' : '0'}`
          )
        }
      }
    }
  }

  return (
    <AppContext.Provider value={context}>
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
